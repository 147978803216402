import ITemplatesClient from "@/interfaces/api/templatesClient";
import { IFilters, IPaginatedTemplates, ITemplate } from "@/interfaces/search";

class TemplatesClient implements ITemplatesClient {
  async getTemplates(limit: number, offset: number, query?: string): Promise<IPaginatedTemplates> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }

    const params = new URLSearchParams({
      limit: String(limit),
      offset: String(offset),
      ...(query ? { query: query } : {})
    });

    try {
      const response = await fetch(`/api/templates/?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data as IPaginatedTemplates
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return {
        data: [] as ITemplate[],
        max_page: 0,
        total: 0,
      }
    }
  }
  async saveTemplate(query?: string, filters?: IFilters): Promise<number> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }

    if ((!query || !query.trim()) && (!filters || Object.keys(filters).length === 0)) {
      throw new Error('Both query and filters cannot be empty.');
    }

    const requestBody: { query?: string, filters?: { [key: string]: string | number | Date } } = {};

    if (query) {
      requestBody.query = query;
    }

    if (filters) {
      const processedFilters: { [key: string]: string | number | Date } = {};
      (Object.keys(filters) as Array<keyof IFilters>).forEach((key) => {
        const value = filters[key];
        if (value !== null && value !== undefined) {
          const snakeCaseKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
          if (value instanceof Date) {
            processedFilters[snakeCaseKey] = value.toISOString().split('T')[0];
          } else {
            processedFilters[snakeCaseKey] = value;
          }
        }
      });
      requestBody.filters = processedFilters;
    }

    try {
      const response = await fetch(`/api/templates/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status == 400) {
        throw new Error('Subscription already exists')
      }
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.subscription_id
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return 0
    }
  }
  async deleteTemplate(templateId: number): Promise<boolean> {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('No token')
    }

    try {
      const response = await fetch(`/api/templates/${templateId}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.success
  
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return false
    }
  }
}

const templatesClient = new TemplatesClient();

export { templatesClient }