
import { defineComponent } from 'vue';

export default defineComponent({
  name: "TheFooter",
  props: {
    isInner: {
      type: Boolean,
      default: false
    }
  }
})
