import ICompanyClient, { ICompany }from "@/interfaces/api/companyClient";


class CompanyClient implements ICompanyClient {
  async getCompany(tin: string): Promise<ICompany | null> {
    const token = localStorage.getItem('token')

    try {
      const response = await fetch(`/api/companies/?tin=${tin}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data as ICompany
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return null
    }
  } 
}

const companyClient = new CompanyClient()

export default companyClient
