const sourceTranslator = {
  'xarid.uzex.uz': 'xarid_uzex',
  'etender.uzex.uz': 'etender',
  'tender.mc.uz': 'mc',
  'xt-xarid.uz': 'xt_xarid',
  'beeline.uz': 'beeline',
}

const typeRouteTranslator = {
  'auction': 'Аукцион',
  'selection': 'Отбор',
  'tender': 'Тендер',
  'civil_contract': 'Гражданско-правовой договор',
  'competition': 'Конкурс',
  'framework': 'Рамочное соглашение'
}

const regionRouteTranslator = {
  'andijan': 'Андижанская область',
  'bukhara': 'Бухарская область',
  'jizzakh': 'Джизакская область',
  'qashqadaryo': 'Кашкадарьинская область',
  'navoiy': 'Навоийская область',
  'namangan': 'Наманганская область',
  'karakalpakstan': 'Республика Каракалпакстан',
  'samarqand': 'Самаркандская область',
  'surxondaryo': 'Сурхандарьинская область',
  'sirdaryo': 'Сырдарьинская область',
  'tashkent_region': 'Ташкентская область',
  'fergana': 'Ферганская область',
  'khorezm': 'Хорезмская область',
  'tashkent_city': 'город Ташкент',
}

const categoryRouteTranslator = {
  'agriculture': '01',
  'forestry': '02',
  'fishing': '03',

  'coal': '05',
  'oil-gas': '06',
  'ores': '07',
  'mining-products': '08',
  'mining-services': '09',

  'food': '10',
  'drinks': '11',
  'tobacco': '12',
  'textile': '13',
  'clothes': '14',
  'leather': '15',
  'wood': '16',
  'paper': '17',
  'print-copy': '18',
  'petroleum-coke': '19',
  'chemicals': '20',
  'medical': '21',
  'rubber-and-plastic': '22',
  'non-metallic-minerals': '23',
  'metals': '24',
  'metal-products': '25',
  'computer-electronics': '26',
  'electrical-equipment': '27',
  'machinery': '28',
  'vehicles': '29',
  'vehicles-other': '30',
  'furniture': '31',
  'other-products': '32',
  'repair-installation': '33',

  'energy-hvac': '35',

  'water': '36',
  'water-disposal': '37',
  'waste-collection': '38',
  'reclamation': '39',

  'building-construction': '41',
  'civil-engineering': '42',
  'specialized-construction': '43',

  'vehicles-trade-and-repair': '45',
  'wholesale-trade': '46',
  'retail-trade': '47',

  'land-and-pipeline-transport': '49',
  'water-transport': '50',
  'air-and-space-transport': '51',
  'transport-services': '52',
  'postal-services': '53',

  'accommodation': '55',
  'catering': '56',

  'publishing': '58',
  'media-production': '59',
  'broadcasting': '60',
  'telecommunications': '61',
  'software-development': '62',
  'information-technology': '63',

  'financial-services': '64',
  'insurance-and-pensions': '65',
  'financial-and-insurance-support': '66',

  'real-estate-services': '68',

  'legal-and-accounting-services': '69',
  'management-services': '70',
  'architecture-and-engineering': '71',
  'research-and-development': '72',
  'advertising-and-market-research': '73',
  'professional-scientific-and-technical-services': '74',
  'veterinary-services': '75',

  'rental-and-leasing': '77',
  'employment-and-recruitment-services': '78',
  'tourism-services': '79',
  'security-and-investigations': '80',
  'building-maintenance': '81',
  'administrative-and-support-services': '82',

  'public-administration-and-security': '84',

  'education': '85',

  'healthcare': '86',
  'residential-care': '87',
  'social-services': '88',

  'creativity-arts-and-entertainment': '90',
  'libraries-and-museums': '91',
  'gambling-and-lotteries': '92',
  'sports-and-recreation': '93',

  'public-services': '94',
  'repair-services': '95',
  'personal-services': '96',

  'domestic-services': '97',
  'private-services': '98',

  'extraterritorial-services': '99'
}

export { sourceTranslator, typeRouteTranslator, regionRouteTranslator, categoryRouteTranslator }
