import { ICompany } from "@/interfaces/api/companyClient";
import { ICompanyState, IRootState } from "@/interfaces/state";
import { ActionContext, Module } from "vuex";
import companyClient from "@/api/companyClient";


const companyModule: Module<ICompanyState, IRootState> = {
  state(): ICompanyState {
    return {
      company: null,
      queryCompany: '',
    };
  },
  getters: {
    company(state: ICompanyState) {
      return state.company
    },
    queryCompany(state: ICompanyState) {
      return state.queryCompany
    },
  },
  mutations: {
    setCompany(state: ICompanyState, payload: { company: ICompany }) {
      state.company = payload.company
    },
    setQueryCompany(state: ICompanyState, payload: { query: string }) {
      state.queryCompany = payload.query
    },
  },
  actions: {
    async getCompany(
      context: ActionContext<ICompanyState, ICompanyState>, 
      payload: { company_tin: string }
    ) {
      const company = await companyClient.getCompany(payload.company_tin)

      if (company == null) {
        context.commit('setCompany', { company: null })
        return null
      }

      context.commit('setCompany', { company: company } )
    },
    setQueryCompany(context, payload: { query: string }) {
      context.commit("setQueryCompany", payload)
    },
  }
}

export default companyModule
