import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tender-item" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "tender-item__info" }
const _hoisted_4 = { class: "tender-item__company" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "tender-item__title" }
const _hoisted_8 = { class: "tender-item__meta" }
const _hoisted_9 = { class: "tender-item__meta-item" }
const _hoisted_10 = { class: "tender-item__meta-title" }
const _hoisted_11 = { class: "tender-item__meta-info" }
const _hoisted_12 = { class: "tender-item__meta-item" }
const _hoisted_13 = { class: "tender-item__meta-title" }
const _hoisted_14 = { class: "tender-item__meta-info" }
const _hoisted_15 = { class: "tender-item__meta-item" }
const _hoisted_16 = { class: "tender-item__meta-title" }
const _hoisted_17 = { class: "tender-item__meta-info" }
const _hoisted_18 = { class: "tender-item__price-mobile" }
const _hoisted_19 = { class: "tender-item__url" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "tender-item__price" }
const _hoisted_22 = { class: "tender-item__favorite" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: '/procedures/' + _ctx.tender.id,
      class: "tender-item__link",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickTender && _ctx.handleClickTender(...args)))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.tender.company_inn)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: `/company/${_ctx.tender.company_inn}?page=1`,
              target: "_blank",
              class: "company-url",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClickCompany && _ctx.handleClickCompany(...args)), ["stop","prevent"]))
            }, _toDisplayString(_ctx.tender.company), 9, _hoisted_5))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.tender.company), 1))
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatName(_ctx.tender.name)), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("tender_details.type")) + ":", 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.tender.type), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("tender_details.ends_on")) + ":", 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.formatDate(_ctx.tender.end_date)), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("tender_details.country")) + ":", 1),
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.tender.country), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.tender.price && _ctx.tender.currency
            ? `${_ctx.tender.price.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })} ${_ctx.tender.currency}`
            : '–'), 1),
      _createElementVNode("div", _hoisted_19, [
        (_ctx.isAuthenticated)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.tender.url,
              target: "_blank",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickSource && _ctx.handleClickSource(...args)))
            }, _toDisplayString(_ctx.sourceTranslator[_ctx.tender.source]), 9, _hoisted_20))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              style: {"cursor":"pointer"},
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClickSource && _ctx.handleClickSource(...args)))
            }, _toDisplayString(_ctx.$t("tender_page.open_source_btn")), 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createTextVNode(_toDisplayString(_ctx.tender.price && _ctx.tender.currency
      ? `${_ctx.tender.price.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })} ${_ctx.tender.currency}`
      : '–') + " ", 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("tender_details.contract_amount")), 1)
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("button", {
        class: _normalizeClass(["btn btn-favorite js-add-favorite", { 'is-selected': _ctx.isFavourite }]),
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleClickFavourite && _ctx.handleClickFavourite(...args)))
      }, null, 2)
    ])
  ]))
}